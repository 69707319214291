/**
 * @flow
 */

import React, { type Element, type Node } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FontAwesomeIcon } from "./FontAwesomeIcon";

import { Text } from "./Text";
import { sharedStyles } from "../styles";

type Props = {|
  inputID: ?string,
  inputName: ?string,
  inputClasses?: string,
  name: ?string,
  value: ?string,
  placeholder?: string,
  icon: Element<*>,
  iconBackgroundColor: string,
  enabled: boolean,
  errors?: Array<string>,
  onFocusInput?: () => void,
  onClickInput?: () => void,
  onClickIcon?: () => void,
  onClose?: () => void,
|};

const styles = StyleSheet.create({
  inputLabel: {
    marginTop: 10,
    marginBottom: 5,
  },
  inputError: {
    marginBottom: 5,
  },
});

export const InputWithIcon = ({
  inputID,
  inputName,
  inputClasses,
  name,
  value,
  icon,
  placeholder,
  onClickIcon,
  iconBackgroundColor,
  enabled,
  errors,
  onClickInput,
  onFocusInput,
  onClose,
}: Props): Node => (
  <View
    style={{
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
    }}
  >
    {name != null && <Text style={[sharedStyles.inputLabelText, styles.inputLabel]}>{name}</Text>}
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {/* Unfortunately we can't use TextInput here because we need to super override the styles as actual
       * inline styles, not as react-native styles, since we're overriding a style from one of our root
       * stylesheet files. That's why we're using a regular 'input' control. */}
      <View>
        {/* FIXME: We should fix this. We should pass in a prop from SingleDayPickerController. */}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <input
          id={inputID}
          className={inputClasses || ""}
          style={{
            width: "auto",
            height: 34,
            zIndex: 0,
            margin: 0,
            paddingHorizontal: 8,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            cursor: "pointer",
          }}
          type="text"
          name={name || inputName}
          value={value || ""}
          placeholder={placeholder}
          onClick={onClickInput}
          onFocus={onFocusInput}
          disabled={!enabled}
          readOnly
        />
        {onClose && value && (
          <TouchableOpacity
            onPress={(e) => {
              e.preventDefault();
              onClose();
            }}
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              right: 0,
              alignItems: "center",
              justifyContent: "center",
              height: 34,
            }}
          >
            <FontAwesomeIcon
              icon={{
                style: "solid",
                name: "times",
              }}
              size={3}
              additionalContainerClasses="date-picker-input-close"
              additionalClasses="has-text-grey"
            />
          </TouchableOpacity>
        )}
      </View>
      <TouchableOpacity
        onPress={onClickIcon}
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: 34,
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
          paddingHorizontal: 10,
          backgroundColor: iconBackgroundColor,
        }}
      >
        {icon}
      </TouchableOpacity>
    </View>
    {errors != null &&
      errors.map((e) => (
        <Text key={`error-${e}`} style={styles.inputError} className="help is-danger is-size-6">
          {e}
        </Text>
      ))}
  </View>
);
