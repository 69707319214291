// @flow

/**
 * Given a count return an array of 1...count.
 * @param count The count of items you want in the resulting array.
 * @returns {Array<number>} An array of 1...count.
 */
export function range(count: number): Array<number> {
  return [...Array(count).keys()];
}

// Adapted from: https://stackoverflow.com/a/33451102/932896
export function arrayRotate<T>(arr: Array<T>, count: number): Array<T> {
  count -= arr.length * Math.floor(count / arr.length);
  return [...arr.slice(count), ...arr.slice(0, count)];
}
