/**
 * @flow
 */

/* global Sentry */

export function logBreadcrumbToSentry(data: Object): void {
  // This is programmed defensively since we don't want attempting to log to throw a different exception
  // and hide the original exception we want reported to us in Sentry.
  if (typeof Sentry !== "undefined") {
    try {
      // If we're in an unknown state, lets send extra data to sentry.
      Sentry.addBreadcrumb({
        data,
        level: Sentry.Severity.Info,
      });
    } catch {
      // no-op
    }
  }
}

export function logErrorBreadcrumbToSentryAndRethrow(exception: Error): void {
  logBreadcrumbToSentry(exception);
  // Re-throw our original exception.
  throw exception;
}
