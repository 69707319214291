/**
 * @flow
 */

import React, { type Element, type Node } from "react";

import type { FontAwesomeRegularIcon, FontAwesomeSolidIcon } from "../types/fontAwesomeTypes";
import type { GenericStyleProp } from "../types/styleTypes";

type FontAwesomeIconSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type FontAwesomeIconSizeSizeShortcut = "tiny" | "small" | "medium" | "large";
export type FontAwesomeIconSizeType = FontAwesomeIconSize | FontAwesomeIconSizeSizeShortcut;
export type FontAwesomeIconType =
  | { style: "solid", name: FontAwesomeSolidIcon }
  | { style: "regular", name: FontAwesomeRegularIcon };

type Props = {
  icon: FontAwesomeIconType,
  // Typically small, medium, or large should be used. Size numbers can be used in exceptional circumstances.
  size: FontAwesomeIconSizeType,
  style?: GenericStyleProp,
  additionalClasses?: string,
  additionalContainerClasses?: string,
  children?: Node,
};

const sizeShortcuts: { [sizeShortcut: FontAwesomeIconSizeSizeShortcut]: FontAwesomeIconSize } = {
  tiny: 0,
  small: 1,
  medium: 5,
  large: 9,
};

const sizeMap = {
  // Do not make a smaller size than this. This is already questionably small for legibility.
  "0": {
    spanClass: "is-small",
    iconClass: "",
    fontSize: "0.85em",
  },
  "1": {
    spanClass: "is-small",
    iconClass: "",
  },
  "2": {
    spanClass: "",
    iconClass: "",
    fontSize: "1.1em",
  },
  "3": {
    spanClass: "",
    iconClass: "",
  },
  "4": {
    spanClass: "is-medium",
    iconClass: "",
  },
  "5": {
    spanClass: "is-medium",
    iconClass: "fa-lg",
  },
  "6": {
    spanClass: "is-medium",
    iconClass: "fa-2x",
  },
  "7": {
    spanClass: "is-large",
    iconClass: "",
  },
  "8": {
    spanClass: "is-large",
    iconClass: "fa-lg",
  },
  "9": {
    spanClass: "is-large",
    iconClass: "fa-2x",
  },
  "10": {
    spanClass: "is-large",
    iconClass: "fa-3x",
  },
};

const styleMap = {
  solid: "fas",
  regular: "far",
};

// eslint-disable-next-line react/function-component-definition
export function FontAwesomeIcon({
  icon,
  size,
  style,
  additionalClasses = "",
  additionalContainerClasses = "",
  children,
}: Props): Element<"span"> {
  const styleClass = styleMap[icon.style];
  const sizeNumber = typeof size === "string" ? sizeShortcuts[size].toString() : size.toString();
  const { fontSize, spanClass: spanSizeClass, iconClass: iconSizeClass } = sizeMap[sizeNumber];

  return (
    <span style={style} className={`icon ${spanSizeClass} ${additionalContainerClasses}`}>
      <i style={{ fontSize }} className={`${styleClass} ${iconSizeClass} fa-${icon.name} ${additionalClasses}`} />
      {children}
    </span>
  );
}
