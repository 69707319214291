/**
 * @flow
 */

import isNaN from "lodash/isNaN";
import flatten from "lodash/flatten";
import keyBy from "lodash/keyBy";
import escapeRegExp from "lodash/escapeRegExp";
import Moment from "moment-timezone";
import { extendMoment } from "moment-range";
import { nextMonth, previousMonth } from "../calendar/react-clean-calendar/util/date";
import { localizedWeekdayNames } from "../calendar/react-clean-calendar/util/localizeDate";
import { InternalOnlyError } from "./errors";
import { logBreadcrumbToSentry } from "./sentryUtils";
import type { Week } from "../calendar/react-clean-calendar/types";
import type { CalendarEvent } from "../../../../sched_common/webpack/shared/models/calendarEvent";
import type { YearMonth } from "../types/dateTypes";

const locale = window.getLanguageCode();
const moment: Class<Moment> = extendMoment(Moment);

export type DayOfWeek = string;
export const DaysOfWeek: Week = localizedWeekdayNames(locale, "long");
export const Weekdays: $ReadOnlyArray<string> = DaysOfWeek.slice(1, 6);
export const Weekends: $ReadOnlyArray<string> = [DaysOfWeek[0], DaysOfWeek[6]];
export const DayOfWeekAbbrs: Week = localizedWeekdayNames(locale, "short");

// We only allow a small subet of options when displaying dates. We should almost always be using the default display
// options, but in certain contexts other more specific display types can be used. Example formatting is provided in
// English for clarity, but you shouldn't assume too much about how these might display in other languages.
export const DateDisplay = Object.freeze({
  default: ("default": "default"), // e.g. Jan 01, 2022
  long: ("long": "long"), // Used for titles and headings. e.g. January 01, 2022
  shortDay: ("shortDay": "shortDay"), // Used in some calendar views. e.g. Jan 1, 2022
  implicitYear: ("implicitYear": "implicitYear"), // Used when the year is readily visible elsewhere. e.g. Jan 1
});
type DateDisplayType = $Keys<typeof DateDisplay>;

export const DateTimeDisplay = Object.freeze({
  default: ("default": "default"),
  withSeconds: ("withSeconds": "withSeconds"),
});
type DateTimeDisplayType = $Keys<typeof DateTimeDisplay>;

/* eslint-disable i18next/no-literal-string */

const timeFormatOptions = {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
};
const defaultDateOptions = {
  month: "short",
  year: "numeric",
  day: "2-digit",
};

const DateTimeFormatKeys = Object.freeze({
  date: ("date": "date"),
  dateWithShortDay: ("dateWithShortDay": "dateWithShortDay"),
  dateWithLongMonth: ("dateWithLongMonth": "dateWithLongMonth"),
  dateWithImplicitYear: ("dateWithImplicitYear": "dateWithImplicitYear"),
  time: ("time": "time"),
  timeWithoutZone: ("timeWithoutZone": "timeWithoutZone"),
  dateTime: ("dateTime": "dateTime"),
  dateTimeWithSeconds: ("dateTimeWithSeconds": "dateTimeWithSeconds"),
});
const DateTimeFormats: { [formatName: string]: Object } = Object.freeze({
  [DateTimeFormatKeys.date]: defaultDateOptions,
  [DateTimeFormatKeys.dateWithShortDay]: {
    ...defaultDateOptions,
    day: "numeric",
  },
  [DateTimeFormatKeys.dateWithLongMonth]: {
    ...defaultDateOptions,
    month: "long",
  },
  [DateTimeFormatKeys.dateWithImplicitYear]: {
    month: defaultDateOptions.month,
    day: defaultDateOptions.day,
  },
  [DateTimeFormatKeys.time]: {
    ...timeFormatOptions,
    timeZoneName: "short",
  },
  [DateTimeFormatKeys.timeWithoutZone]: {
    ...timeFormatOptions,
  },
  [DateTimeFormatKeys.dateTime]: {
    ...defaultDateOptions,
    ...timeFormatOptions,
    timeZoneName: "short",
  },
  [DateTimeFormatKeys.dateTimeWithSeconds]: {
    ...defaultDateOptions,
    ...timeFormatOptions,
    timeZoneName: "short",
    second: "2-digit",
  },
});

/* eslint-enable i18next/no-literal-string */

// Unfortunately the code involved in tracking instantiated Intl.DateTimeFormat classes is pretty messy because each
// change to any option requires a different class instance with a different set of options. We maintain instantiated
// formatters to avoid instantiating extra objects, for cases where we need to format many dates on one page, but this
// means we've ended up with a decent chunk of code to track those instances in the 'zonedFormatters' object.
const zonedFormatterKeyFn = (dateTimeFormatKey: $Values<typeof DateTimeFormatKeys>, timeZone: string): string => {
  return `${timeZone}-${dateTimeFormatKey}`;
};
const zonedFormatters: { string: Intl.DateTimeFormat } = {};

const getTimeFormatter = (timeZone: string, displayTimeZone: boolean = true) => {
  const dateTimeFormatKey = displayTimeZone ? DateTimeFormatKeys.time : DateTimeFormatKeys.timeWithoutZone;
  const zonedFormatKey = zonedFormatterKeyFn(dateTimeFormatKey, timeZone);
  if (zonedFormatters[zonedFormatKey] === undefined) {
    zonedFormatters[zonedFormatKey] = global.Intl.DateTimeFormat(locale, {
      timeZone,
      ...DateTimeFormats[dateTimeFormatKey],
    });
  }
  return zonedFormatters[zonedFormatKey];
};

const dateFormatMap = new Map([
  [DateDisplay.default, DateTimeFormatKeys.date],
  [DateDisplay.long, DateTimeFormatKeys.dateWithLongMonth],
  [DateDisplay.implicitYear, DateTimeFormatKeys.dateWithImplicitYear],
  [DateDisplay.shortDay, DateTimeFormatKeys.dateWithShortDay],
]);
const getDateFormatter = (timeZone: string, dateDisplay: DateDisplayType = DateDisplay.default) => {
  const dateFormatKey = dateFormatMap.get(dateDisplay);
  if (dateFormatKey == null) {
    throw new InternalOnlyError("Invalid date time format supplied.");
  }
  const zonedFormatKey = zonedFormatterKeyFn(dateFormatKey, timeZone);
  if (zonedFormatters[zonedFormatKey] === undefined) {
    zonedFormatters[zonedFormatKey] = global.Intl.DateTimeFormat(locale, {
      timeZone,
      ...DateTimeFormats[dateFormatKey],
    });
  }
  return zonedFormatters[zonedFormatKey];
};

const dateTimeFormatMap = new Map([
  [DateTimeDisplay.default, DateTimeFormatKeys.dateTime],
  [DateTimeDisplay.withSeconds, DateTimeFormatKeys.dateTimeWithSeconds],
]);
const getDateTimeFormatter = (timeZone: string, dateTimeDisplay: DateTimeDisplayType = DateTimeDisplay.default) => {
  const dateTimeFormatKey = dateTimeFormatMap.get(dateTimeDisplay);
  if (dateTimeFormatKey == null) {
    throw new InternalOnlyError("Invalid date time format supplied.");
  }
  const zonedFormatKey = zonedFormatterKeyFn(dateTimeFormatKey, timeZone);
  if (zonedFormatters[zonedFormatKey] === undefined) {
    zonedFormatters[zonedFormatKey] = global.Intl.DateTimeFormat(locale, {
      timeZone,
      ...DateTimeFormats[dateTimeFormatKey],
    });
  }
  return zonedFormatters[zonedFormatKey];
};

type RangePart = {
  source: string,
  type: string,
  value: string,
};
type HourMinuteZoneRangePart = {
  source: "shared" | "endRange" | "startRange",
  type: "hour" | "minute" | "timeZoneName",
  value: string,
};
// This type is an incomplete representation of what 'formatRangeToParts' can return, by design.
// We're only defining the parts that we care about / work with.
type HourMinuteZoneRangeParts = {
  "shared-hour"?: HourMinuteZoneRangePart,
  "shared-minute"?: HourMinuteZoneRangePart,
  "shared-timeZoneName"?: HourMinuteZoneRangePart,
  "startRange-hour"?: HourMinuteZoneRangePart,
  "startRange-minute"?: HourMinuteZoneRangePart,
  "startRange-timeZoneName"?: HourMinuteZoneRangePart,
  "endRange-hour"?: HourMinuteZoneRangePart,
  "endRange-minute"?: HourMinuteZoneRangePart,
  "endRange-timeZoneName"?: HourMinuteZoneRangePart,
};
const getHourMinuteZoneRangeParts = (timestamp1: moment, timestamp2: moment): HourMinuteZoneRangeParts => {
  // Only bother with the specific parts we care about, hour, minute, and timeZoneName.
  const hourMinuteZoneRangeParts = getTimeFormatter(timestamp1.tz())
    .formatRangeToParts(timestamp1.toDate(), timestamp2.toDate())
    .filter((part) => ["hour", "minute", "timeZoneName"].includes(part.type));
  return keyBy(
    // $FlowExpectedError - our flow types are missing some newer Intl methods like 'formatRangeToParts'
    hourMinuteZoneRangeParts,
    (item) => `${item.source}-${item.type}`
  );
};

type TimeZoneRangeParts = {
  startTimeZoneName: string,
  endTimeZoneName: string,
  startAndEndTimeZoneAreTheSame: boolean,
};
const getTimeZoneDataFromParts = (rangeParts: HourMinuteZoneRangeParts): TimeZoneRangeParts => {
  // You might ask why we need this function at all if we get 'shared-timeZoneName' data. We don't get this shared
  // value in all cases where startRange zone equals endRange zone, so we do need this logic.
  if (rangeParts["shared-timeZoneName"]) {
    return {
      startTimeZoneName: rangeParts["shared-timeZoneName"].value,
      endTimeZoneName: rangeParts["shared-timeZoneName"].value,
      startAndEndTimeZoneAreTheSame: true,
    };
  }
  // We shouldn't get null values at this point, but without writing an extremely complicated union type for
  // 'HourMinuteZoneRangeParts', Flow isn't going to know that, so use an empty string as a default value if
  // everything is null.
  const startTimeZoneName = rangeParts["startRange-timeZoneName"]?.value || "";
  const endTimeZoneName = rangeParts["endRange-timeZoneName"]?.value || "";
  const startAndEndTimeZoneAreTheSame = startTimeZoneName === endTimeZoneName;
  return {
    startTimeZoneName,
    endTimeZoneName,
    startAndEndTimeZoneAreTheSame,
  };
};

const applyCustomTimeTransformations = (inStr: string) => {
  let transformedString = inStr;
  if (locale.toUpperCase() === "FR-CA") {
    // $FlowExpectedError - our flow types are missing this newish method, 'replaceAll'
    transformedString = transformedString.replaceAll(
      " h ", // eslint-disable-line i18next/no-literal-string
      ":" // eslint-disable-line i18next/no-literal-string
    );
  }
  return transformedString;
};

/** A method for returning the formatted time portion of a timestamp. e.g. '10:30 CST'.
 *
 * @param timestamp - the timestamp to format the time portion of.
 * @param displayZone - set false to not display the timezone. We rarely want this, but if this is a time input
 *  field, or the zone is displayed in a table column beside the time data, this might be needed.
 * @returns {string}
 */
export const l10nTimeFormat = (timestamp: moment, displayZone: boolean = true): string => {
  const formattedTime = getTimeFormatter(timestamp.tz(), displayZone).format(timestamp.toDate());
  return applyCustomTimeTransformations(formattedTime);
};

/** This method accepts a dateISOString and not a moment, because its unclear whether a moment object is in the
 * appropriate timezone or not for display. This forces the decision onto the method caller. In many cases where we
 * call this method we already have a local ISO string like "2020-01-01" (this is typically what our server gives us),
 * which this method will appropriately display as Jan 1, 2020. In cases where we have a moment that we want to display
 * the date of, whoever wants to display that date is responsible for getting the timestamp into the appropriate local
 * "YYYY-MM-DD" format.
 *
 * @param dateISOString
 * @param dateDisplay - specify how the date should look. Only certain customization is allowed.
 * @returns {string}
 */
export const l10nDateFormatFromISODate = (
  dateISOString: string,
  dateDisplay: DateDisplayType = DateDisplay.default
): string => {
  // For parsing an ISO date string, we use the JS Date object directly. For more complex timestamp parsing we use
  // moment.js instead. The downside to this is we don't get meaningful error info, but that shouldn't come up much.
  const date = Date.parse(dateISOString);
  if (isNaN(date)) {
    throw new InternalOnlyError("We received an invalid dateISOString.");
  }
  return getDateFormatter("UTC", dateDisplay).format(date);
};

export function l10nDateFormatFromISOTimestamp(
  isoTimestamp: string,
  timeZone: string,
  dateDisplay: DateDisplayType = DateDisplay.default
): string {
  // Sometimes we get fractional seconds and sometimes we don't. Handle both. 4 or more capital S characters will
  // accept 4 to 9 decimal places.
  const expectedFormats = [
    /* eslint-disable i18next/no-literal-string */
    "YYYY-MM-DDTHH:mm:ssZZ",
    "YYYY-MM-DDTHH:mm:ss.SSSSZZ",
    /* eslint-enable i18next/no-literal-string */
  ];
  const dateMoment = moment(isoTimestamp, expectedFormats, true);
  if (!dateMoment.isValid()) {
    logBreadcrumbToSentry({
      isoTimestamp,
      expectedFormats,
      parseError: JSON.stringify(dateMoment.parsingFlags()),
    });
    throw new InternalOnlyError("We received an invalid dateISOString.");
  }
  return getDateFormatter(timeZone, dateDisplay).format(dateMoment.tz(timeZone).toDate());
}

/** Hopefully you've clicked into this method to figure out why we're calling this dangerous. At the end of the day,
 * this is fine to use, but you need to make sure you've constructed your moment AND THEN called .tz() on that moment
 * object or this will output the wrong string.
 *
 * GOOD: l10nTimestampFormatDangerouslyFromMoment(moment("2023-01-14T15:00:00+00:00").tz("America/Regina"))
 * BAD:  l10nTimestampFormatDangerouslyFromMoment(moment("2023-01-14T15:00:00+00:00"))
 *
 * Alternatively, we'll typically have the timestamp ISO string and the timeZone string as separate variables, in which
 * case you can call 'l10nTimestampFormat'. Why wouldn't you do that all the time? If you need to instantiate a
 * moment object to do logic with AND ALSO display it, then it would make sense to pass the already instantiated
 * moment object into this method, rather than calling 'l10nTimestampFormat', which would instantiate a duplicate
 * moment. In performance sensitive contexts, making extra moment objects is bad.
 *
 * @param timestamp
 * @param dateTimeDisplay
 * @returns {string}
 */
export const l10nTimestampFormatDangerouslyFromMoment = (
  timestamp: moment,
  dateTimeDisplay: DateTimeDisplayType = DateTimeDisplay.default
): string => {
  const formattedTimestamp = getDateTimeFormatter(timestamp.tz(), dateTimeDisplay).format(timestamp.toDate());
  return applyCustomTimeTransformations(formattedTimestamp);
};

export const l10nTimestampFormat = (timestampISOString: string, timeZone: string): string => {
  return l10nTimestampFormatDangerouslyFromMoment(moment(timestampISOString).tz(timeZone));
};

export const collapseIdenticalTimeZoneNamesInTimestampRange = (
  formattedTimestampRange: string,
  startTimeZoneName: string,
  startAndEndTimeZoneAreTheSame: boolean
): string => {
  if (startAndEndTimeZoneAreTheSame) {
    // We need to make sure we don't strip the timeZone if it would only appear once, which it can if the date range is
    // being collapsed in the timestamp, e.g. Nov 12, 2023, 12:36 – 13:36 CST.
    const timeZoneRegex = new RegExp(escapeRegExp(startTimeZoneName), "g");
    const timeZoneMatches = formattedTimestampRange.match(timeZoneRegex);
    // If timezoneMatches is null, something weird is up, but better to just not alter the string than to crash.
    if (timeZoneMatches != null && timeZoneMatches.length > 1) {
      // At this point we know the start and end timestamp both had the same timezone and the browser is trying to
      // render it twice, by default. In those cases we strip out the first instance of the timeZone, so we'd render:
      //    Nov 12, 2023, 12:36 – 13:36 CST
      // instead of:
      //    Nov 12, 2023, 12:36 CST – 13:36 CST
      // We have the same logic when doing timestamp range formatting in Python.
      return formattedTimestampRange.replace(` ${startTimeZoneName}`, "");
    }
  }
  return formattedTimestampRange;
};

export const l10nTimestampRangeFormat = (timestamp1: moment, timestamp2: moment): string => {
  const rangeParts = getHourMinuteZoneRangeParts(timestamp1, timestamp2);
  const { startTimeZoneName, startAndEndTimeZoneAreTheSame } = getTimeZoneDataFromParts(rangeParts);
  // $FlowExpectedError - our flow types are missing some newer Intl methods like 'formatRange'
  let formattedTimestampRange = getDateTimeFormatter(timestamp1.tz()).formatRange(
    timestamp1.toDate(),
    timestamp2.toDate()
  );
  formattedTimestampRange = collapseIdenticalTimeZoneNamesInTimestampRange(
    formattedTimestampRange,
    startTimeZoneName,
    startAndEndTimeZoneAreTheSame
  );
  return applyCustomTimeTransformations(formattedTimestampRange);
};

export const l10nDateRangeFormat = (
  timestamp1: moment,
  timestamp2: moment,
  dateDisplay: DateDisplayType = DateDisplay.default
): string => {
  // $FlowExpectedError - our flow types are missing some newer Intl methods like 'formatRangeToParts'
  const rangeParts = getDateFormatter(timestamp1.tz(), dateDisplay).formatRangeToParts(
    timestamp1.toDate(),
    timestamp2.toDate()
  );
  // Likely because of the way the Intl built-in does format matching, it isn't properly formatting the day values
  // as 2-digit, even though 'dateFormatter' is defined that way, so we manaully 0-pad the day values, but we do so
  // without altering or changing the order of elements we get from the Intl built-in.
  return rangeParts
    .map(({ type, value }: RangePart): string => {
      return type === "day" && dateDisplay !== DateDisplay.shortDay ? value.padStart(2, "0") : value;
    })
    .join("");
};

export const l10nTimeRangeFormat = (timestamp1: moment, timestamp2: moment): string => {
  const rangeParts = getHourMinuteZoneRangeParts(timestamp1, timestamp2);
  const { startTimeZoneName, endTimeZoneName, startAndEndTimeZoneAreTheSame } = getTimeZoneDataFromParts(rangeParts);
  const startRangeHour = rangeParts["startRange-hour"]?.value || rangeParts["shared-hour"]?.value;
  const startRangeMinute = rangeParts["startRange-minute"]?.value || rangeParts["shared-minute"]?.value;
  const endRangeHour = rangeParts["endRange-hour"]?.value || rangeParts["shared-hour"]?.value;
  const endRangeMinute = rangeParts["endRange-minute"]?.value || rangeParts["shared-minute"]?.value;
  if (
    startTimeZoneName === undefined ||
    endTimeZoneName === undefined ||
    startRangeHour === undefined ||
    startRangeMinute === undefined ||
    endRangeHour === undefined ||
    endRangeMinute === undefined
  ) {
    throw new InternalOnlyError(`Formatting failed, please make sure valid timestamps were passed in. 
    timestamp1: ${timestamp1.format()}, timestamp2: ${timestamp2.format()}`);
  }

  /* eslint-disable i18next/no-literal-string */
  return (
    `${startRangeHour}:${startRangeMinute} ` +
    `${startAndEndTimeZoneAreTheSame ? "" : `${startTimeZoneName} `}– ` +
    `${endRangeHour}:${endRangeMinute} ${endTimeZoneName}`
  );
  /* eslint-enable i18next/no-literal-string */
};

/* eslint-enable i18next/no-literal-string */

/**
 * Typically you're dealing with a datetime and you can simply get whatever date string format you want from that
 * date. This method is not for those times. If you happen to have a long weekday string, and you want an abbreviated
 * weekday string, and no date related to it, this is the method for you.
 *
 * NOTE: This method is a complete disaster if you consider localization. If there comes a time when that becomes a
 * real concern for us, this method will have to change. As long as BC-English doesn't translate one of the days of
 * the week to be another word, this method should serve our purposes just fine, for now.
 *
 * @param dayOfWeek
 * @returns DayOfWeekAbbr
 */
export const getDayOfWeekAbbr = (dayOfWeek: string): string => {
  if (!DaysOfWeek.includes(dayOfWeek)) {
    throw new InternalOnlyError("Invalid day of the week.");
  }
  return DayOfWeekAbbrs[DaysOfWeek.indexOf(dayOfWeek)];
};

const getDayOfWeekIndex = (day: string): number => {
  const index = DaysOfWeek.indexOf(day);
  if (index === -1) {
    throw new InternalOnlyError(
      "A 'DayOfWeek' has no associated day index. Our concept of time is irrecoverably broken."
    );
  }
  return index;
};

export const dayOfWeekBeforeDay = (day: string): string => {
  if (!DaysOfWeek.includes(day)) {
    throw new InternalOnlyError("Invalid day of the week.");
  }
  const index = getDayOfWeekIndex(day);
  return DaysOfWeek[index === 0 ? 6 : index - 1];
};

export const dayOfWeekAfterDay = (day: string): string => {
  if (!DaysOfWeek.includes(day)) {
    throw new InternalOnlyError("Invalid day of the week.");
  }
  const index = getDayOfWeekIndex(day);
  return DaysOfWeek[index === 6 ? 0 : index + 1];
};

export function sortDaysAccordingToPositionInWeek(l: string, r: string): number {
  if (!DaysOfWeek.includes(l) || !DaysOfWeek.includes(r)) {
    throw new InternalOnlyError("Invalid day of the week.");
  }
  return DaysOfWeek.indexOf(l) - DaysOfWeek.indexOf(r);
}

export function mapDayRange<A>(startDate: moment, endDate: moment, mapFn: (moment) => A): Array<A> {
  const startDateNative = startDate.toDate();
  const endDateNative = endDate.toDate();
  const result: Array<A> = [];
  // We do the date addition with native JS dates, but the mapFn has a moment passed into it.
  for (let d = new Date(startDateNative.valueOf()); d <= endDateNative; d.setDate(d.getDate() + 1)) {
    result.push(mapFn(moment(d)));
  }
  return result;
}

export function iterateDayRange<A>(startDate: moment, endDate: moment, iterFn: (moment) => A): void {
  const startDateNative = startDate.toDate();
  const endDateNative = endDate.toDate();
  for (let d = new Date(startDateNative.valueOf()); d <= endDateNative; d.setDate(d.getDate() + 1)) {
    iterFn(moment(d));
  }
}

/**
 * Given a start and end date, this function groups every day including and between them into an Array of Arrays.
 * The outer array is an array of weeks. The inner array will always be exactly 7 days. If not all of the days of
 * a week are within the given range, the days outside the range are set to null.
 *
 * @param startDate The start date of the range of days to group into weeks.
 * @param endDate The end date of the range of days to group into weeks.
 * @returns {Array}
 */
export function dayRangeGroupedIntoWeekArrays(startDate: moment, endDate: moment): Array<Array<?moment>> {
  let iterDate = null;
  const weeks = [];
  const startDateDayOfWeek = startDate.day();
  const firstWeek = [null, null, null, null, null, null, null];

  // Handle the first (partial) week (if necessary),
  // if the last day of the month ALSO ends before the end of the first week.
  if (startDate.isSame(endDate, "week")) {
    const endOfFirstWeek = mapDayRange(startDate, endDate, (date) => date.clone());

    // $FlowExpectedError
    Array.prototype.splice.apply(firstWeek, [startDateDayOfWeek, endOfFirstWeek.length].concat(endOfFirstWeek));
    weeks.push(firstWeek);
    return weeks;
  }

  // Handle the first (partial) week (if necessary), if the last day of the first week is equal to 7.
  if (startDateDayOfWeek !== 0) {
    const difference = 7 - startDateDayOfWeek;
    const endOfFirstWeek = mapDayRange(startDate, startDate.clone().add(difference - 1, "days"), (date) =>
      date.clone()
    );

    // $FlowExpectedError
    Array.prototype.splice.apply(firstWeek, [startDateDayOfWeek, endOfFirstWeek.length].concat(endOfFirstWeek));
    weeks.push(firstWeek);
    iterDate = endOfFirstWeek[endOfFirstWeek.length - 1].clone().add(1, "days");
  }

  // Handle full weeks.
  iterDate = iterDate || startDate.clone();
  let iterEndOfWeek = iterDate.clone().add(6, "days");
  while (iterEndOfWeek.isSameOrBefore(endDate)) {
    weeks.push(mapDayRange(iterDate, iterEndOfWeek, (date) => date.clone()));
    iterDate = iterEndOfWeek.clone().add(1, "days");
    iterEndOfWeek = iterDate.clone().add(6, "days");
  }

  // Handle the last (partial) week (if necessary).
  if (iterDate.isSameOrBefore(endDate)) {
    const lastWeek = [null, null, null, null, null, null, null];
    const lastWeekDates = mapDayRange(iterDate, endDate, (date) => date.clone());
    // $FlowExpectedError
    Array.prototype.splice.apply(lastWeek, [0, lastWeekDates.length].concat(lastWeekDates));
    weeks.push(lastWeek);
  }

  return weeks;
}

export function yearMonthsOnOrBetweenDates(startMoment: moment, endMoment: moment): Array<YearMonth> {
  const months = [];
  const newStartMoment = startMoment.clone();
  while (endMoment.isAfter(newStartMoment) || newStartMoment.format("M") === endMoment.format("M")) {
    // Convert month from moment's 0-indexed value to our 1-indexed value.
    months.push({ year: newStartMoment.year(), month: newStartMoment.month() + 1 });
    newStartMoment.add(1, "month");
  }
  return months;
}

export function flatMapDayRange<A>(startDate: moment, endDate: moment, mapFn: (moment) => Array<A> | A): Array<A> {
  return flatten(mapDayRange(startDate, endDate, mapFn));
}

export function mergeMomentRanges(ranges: Array<any>): Array<any> {
  const sortedRanges = ranges.sort((a, b) => a.start.diff(b.start));
  const mergedRanges = [];
  sortedRanges.forEach((range) => {
    let merged = null;
    const lastArrayIndex = mergedRanges.length - 1;
    if (mergedRanges[lastArrayIndex] != null) {
      const lastRange = mergedRanges[lastArrayIndex];
      // Since by default 2015-01-01 - 2015-01-02, 2015-01-03 - 2015-01-04 would not merge (since the days are beside
      // eachother, not overlapping) we attempt the merge after adding one day to the left-side range. The moment-range
      // add function's { adjacent: true } flag would join the ranges 2015-01-01 - 2015-01-02, 2015-01-02 - 2015-01-03,
      // but it does not handle joining 1-day apart ranges, which is what we want.
      // $FlowExpectedError
      const lastRangePlusOneDay = moment.range(lastRange.start, lastRange.end.add(1, "days"));
      merged = lastRangePlusOneDay.add(range, { adjacent: true });
      if (merged != null) {
        mergedRanges[lastArrayIndex] = merged;
      }
    }
    // If we couldn't merge the candidate range, append it as its own range.
    if (!merged) {
      mergedRanges.push(range);
    }
  });
  return mergedRanges;
}

/**
 *
 * @param dateRange
 * @param ranges
 * @returns {boolean}
 */
export function isDateRangeContainedByDateRanges(
  // $FlowExpectedError
  dateRange: moment.range,
  // $FlowExpectedError
  ranges: Array<moment.range>
): boolean {
  const rangesToCheck = mergeMomentRanges(ranges);
  return rangesToCheck.some((range) => dateRange.start.within(range) && dateRange.end.within(range));
}

/**
 * Check if a date range is within a set of date ranges.
 *
 * @param dateRange
 * @param ranges
 * @param merge If you know the incoming ranges are already merged (to minimize the ranges to check), pass false
 *   to avoid the unnecessary computation of merging them again.
 * @returns {boolean}
 */
export function isDateRangeOverlappingOneOrMoreDateRanges(
  dateRange: any,
  ranges: Array<any>,
  merge: boolean = true
): boolean {
  const rangesToCheck = merge ? mergeMomentRanges(ranges) : ranges;
  return rangesToCheck.some((range) => range.overlaps(dateRange, { adjacent: true }));
}

export function isDateWithinDateRanges(date: moment, ranges: Array<any>): boolean {
  return mergeMomentRanges(ranges).filter((range) => range.contains(date)).length > 0;
}

export const areDaysTheSame = (selectionStartDate: moment, selectionEndDate: moment): boolean =>
  selectionStartDate.isBefore(selectionEndDate, "day");

export const eventsOnDate = (dateISOStr: ?string, events: Array<CalendarEvent>): Array<CalendarEvent> =>
  events.filter((event) => event.date === dateISOStr);

export const yearMonthsForMonthAndAdjacentMonths = (year: number, month: number): Array<YearMonth> => {
  return [previousMonth(year, month), { year, month }, nextMonth(year, month)];
};

function sortYearMonths(firstYearMonth1: YearMonth, secondYearMonth: YearMonth): -1 | 0 | 1 {
  if (firstYearMonth1.year < secondYearMonth.year) {
    return -1;
  }
  if (firstYearMonth1.year > secondYearMonth.year) {
    return 1;
  }

  if (firstYearMonth1.month < secondYearMonth.month) {
    return -1;
  }
  if (firstYearMonth1.month > secondYearMonth.month) {
    return 1;
  }
  return 0;
}

export function minAndMaxYearMonthInArray(yearMonths: Array<YearMonth>): [YearMonth, YearMonth] {
  if (yearMonths.length === 0) {
    throw new InternalOnlyError("You can't find the min and max of nothing.");
  }
  yearMonths.sort(sortYearMonths);
  return [yearMonths[0], yearMonths[yearMonths.length - 1]];
}

export function getNearestDateInSortedListOnOrAfterDate(dateISOStringList: Array<string>, date: string): ?string {
  // Since we have ISO date, which are neatly sortable, we don't need to create moment objects for the comparison.
  return dateISOStringList.find((itemDate) => itemDate >= date);
}

/**
 * This function documents why we're adding 1 to month values in several places (because we're converting from 0-to-1
 * indexing, in case that wasn't clear).
 *
 * @param zeroIndexedMonth
 * @returns {number}
 */
export function zeroToOneIndexedMonth(zeroIndexedMonth: number): number {
  return zeroIndexedMonth + 1;
}
