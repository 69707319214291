/**
 * @flow
 */

import { StyleSheet } from "react-native";

/* eslint-disable i18next/no-literal-string */
export const whiteColor = "white";
export const blackColor = "black";
export const statHolidayGrey = "grey";
/* eslint-enable i18next/no-literal-string */

export const todayColor = "#6c31a0";
export const scheduledShiftBlue = "#21619f";
export const warningColor = "#FF920D"; // If you're putting text on top of this color, use black text.
export const notWorkingGrey = "#595959";
export const calendarEventBlue = "#3498db";
export const errorRed = "#EE0000"; // Darker than solid red for better contrast. Use white text on top of this.
export const successGreen = "#008000";

export const sharedStyles: any = StyleSheet.create({
  heading: { fontSize: 20 },
  buttonBase: {
    borderWidth: 1,
    borderRadius: 2,
    borderColor: "grey",
    backgroundColor: "#DCDCDC",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 1,
  },
  inputLabelText: { color: "#555", fontWeight: "bold" },
});
