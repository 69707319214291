/**
 * @flow
 */

import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";

const decodeJSON = (encodedJSON: string): any => JSON.parse(atob(encodedJSON));

export const decodeObject = (json: any): any => {
  return decodeJSON(json);
};

export const decodeString = (json: any): string => {
  const decoded = decodeJSON(json);
  if (!isString(decoded)) {
    throw new TypeError(`Expected type 'string', got ${typeof decoded}. Decoded: ${decoded} from ${json}.`);
  }
  return decoded;
};

export const decodeInt = (json: any): number => {
  const decoded = decodeJSON(json);
  if (Number.isNaN(decoded)) {
    throw new TypeError(`Expected a value that could be parsed as an integer, got ${decoded} from ${json}.`);
  }
  return decoded;
};

export const decodeFloat = (json: any): number => {
  const decoded = decodeJSON(json);
  if (Number.isNaN(decoded)) {
    throw new TypeError(`Expected a value that could be parsed as a float, got ${decoded} from ${json}.`);
  }
  return decoded;
};

/**
 * We're expecting either a JSON boolean or exactly the string "true" or "false".
 * @param json
 * @returns {boolean}
 */
export const decodeBoolean = (json: any): boolean => {
  const decoded = decodeJSON(json);
  if (!isBoolean(decoded)) {
    throw new TypeError(`Expected type 'boolean', got ${typeof decoded}. Decoded: ${decoded} from ${json}.`);
  }
  return decoded;
};
