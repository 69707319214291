/**
 * @flow
 */

import moment from "moment-timezone";
import React, { useState, type Node } from "react";
import ReactDOM from "react-dom";
import { View } from "react-native";

import { SingleDayPickerController } from "../../shared/components/SingleDayPickerController";
import { decodeObject } from "../../shared/utils/decode";

const reactCollection: HTMLCollection<HTMLElement> = document.getElementsByClassName("react-datepickercontainer");

type Props = {
  index: number,
  inputID: string,
  inputName: string,
  inputErrors: Array<string>,
  inputInitialDate: ?moment,
  hideClearButton: boolean,
};

/**The single day picker controller manages two things:
 * 1) a hidden form input field that maintains the selected date with a value in ISO-8601 format, e.g. YYYY-MM-DD.
 * 2) a displayed input field that renders the date in a format appropriate for the users locale, e.g. 15 juin 2023.
 * When the form is submitted, only the first ISO-8601 represented field is sent as part of the GET request payload.
 *
 * @param index - An offset for the zIndex. This helps stack one date picker on top of another on the same page.
 * @param inputID - The ID for the input. This will be set as the id on the hidden form input described above.
 * @param inputName - The name for the input. This will be set as the id on the hidden form input described above.
 * @param inputErrors - Any field errors for this input.
 * @param inputInitialDate - If provided, he initial date for the input field. Otherwise null is used.
 * @param hideClearButton - Don't allow the date to be cleared.
 * @returns {JSX.Element}
 * @constructor
 */
export const SingleDayPickerControllerContainer = ({
  index,
  inputID,
  inputName,
  inputErrors,
  inputInitialDate,
  hideClearButton,
}: Props): Node => {
  const [date, setDate] = useState<?moment>(inputInitialDate);
  return (
    <View style={{ width: "100%", height: "100%" }}>
      {/* The Django forms that handle our form submissions specifically want YYYY-MM-DD. */}
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <input id={inputID} name={inputName} value={date?.format("YYYY-MM-DD") || ""} type="hidden" />
      <SingleDayPickerController
        showInput
        inputEnabled
        inputErrors={inputErrors}
        hasError={inputErrors != null && inputErrors.length > 0}
        date={date}
        hideClearButton={hideClearButton}
        zIndex={9999 - index}
        onDateSelected={(newDate) => {
          setDate(newDate);
        }}
      />
    </View>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  if (reactCollection) {
    const reactContainers = [...Array(reactCollection.length).keys()].map((i) => reactCollection[i]);
    reactContainers.forEach((reactContainer, index) => {
      const { inputId, inputName, inputErrors, inputInitialDate, inputHideClearButton } = reactContainer.dataset;
      const inputInitialMoment = inputInitialDate != null ? moment(inputInitialDate) : null;
      ReactDOM.render(
        <SingleDayPickerControllerContainer
          index={index}
          inputID={inputId}
          inputName={inputName}
          inputErrors={decodeObject(inputErrors)}
          inputInitialDate={inputInitialMoment}
          hideClearButton={inputHideClearButton === "True"}
        />,
        reactContainer
      );
    });
  }
});
