/**
 * @flow
 */

import React, { useState, type Node } from "react";
import { DayPickerSingleDateController } from "react-dates";
import { StyleSheet, View } from "react-native";
import moment from "moment-timezone";
import RelativePortal from "react-relative-portal";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { useWindowWidth } from "../hooks/useWindowWidth";
import { scheduledShiftBlue } from "../styles";
import { l10nDateFormatFromISODate } from "../utils/dateUtils";
import { FontAwesomeIcon } from "./FontAwesomeIcon";
import { InputWithIcon } from "./InputWithIcon";

type Props = {|
  inputID?: string,
  inputName?: string,
  inputErrors?: Array<string>,
  hasError?: boolean,
  name?: string,
  date?: ?moment,
  placeholder?: string,
  initialVisibleMonth?: () => moment,
  isOutsideRange?: (moment) => boolean,
  showInput: boolean,
  inputEnabled: boolean,
  zIndex: number,
  hideClearButton?: ?boolean,
  onDateSelected: (date: ?moment) => void,
|};

// The regular day width works fine on an iPhone 6/7/8 screen width (375px).
// The regular day width is too wide for an iPhone SE screen width (320px).
// As such, we break to a smaller day width as soon as we're under 375 pixels.
const SmallDayWidthBreakpoint = 375;
const SmallDayWidth = 33;

const styles = StyleSheet.create({
  dateInputContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    height: "100%",
  },
});

/**
 * This 'controller' component is based on an example this library provides:
 * https://github.com/airbnb/react-dates/blob/master/examples/DayPickerSingleDateControllerWrapper.jsx
 *
 * The date field in `field.html` is set up to match this, so if you make style/layout changes
 * here please carry them over to that file.
 */
export const SingleDayPickerController = ({
  name,
  zIndex,
  date,
  showInput,
  inputEnabled,
  onDateSelected,
  placeholder,
  hasError,
  inputID,
  inputName,
  inputErrors,
  hideClearButton = false,
  ...leftOverProps
}: Props): Node => {
  // The click that opens the day picker propogates and ends up counting as a click outside the day picker, which then
  // closes the picker. In order to prevent this, we use this flag to ignore the first onOutClick.
  const [showingDatePickerInProgress, setShowingDatePickerInProgress] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const windowWidth = useWindowWidth();
  const dateString = date && l10nDateFormatFromISODate(date.format("YYYY-MM-DD"));
  const inputClasses = ["input", hasError ? "is-danger" : null].join(" ");
  return (
    <View style={[styles.dateInputContainer, { zIndex }]}>
      <div>
        {showInput && (
          <InputWithIcon
            inputID={inputID}
            inputName={inputName}
            inputClasses={inputClasses}
            name={name}
            value={dateString}
            placeholder={placeholder}
            icon={
              <FontAwesomeIcon
                icon={{ style: "solid", name: "calendar-alt" }}
                size={4}
                additionalClasses="has-text-white"
              />
            }
            iconBackgroundColor={scheduledShiftBlue}
            enabled={inputEnabled}
            errors={inputErrors}
            onClickInput={() => {
              setShowingDatePickerInProgress(true);
              setShowDatePicker(true);
            }}
            onClickIcon={() => {
              setShowingDatePickerInProgress(true);
              setShowDatePicker(true);
            }}
            onClose={
              !hideClearButton
                ? () => {
                    onDateSelected(null);
                    setShowDatePicker(false);
                  }
                : undefined
            }
          />
        )}
        <RelativePortal
          /* eslint-disable-next-line i18next/no-literal-string */
          component="div"
          left={0}
          top={5}
          onOutClick={() => {
            if (showDatePicker) {
              if (showingDatePickerInProgress) {
                setShowingDatePickerInProgress(false);
              } else {
                setShowDatePicker(false);
              }
            }
          }}
        >
          {showDatePicker && (
            // For z-index to apply, we need position absolute.
            <div style={{ position: "absolute", zIndex }}>
              <DayPickerSingleDateController
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...leftOverProps}
                focused
                hideKeyboardShortcutsPanel
                daySize={windowWidth < SmallDayWidthBreakpoint ? SmallDayWidth : undefined}
                onDateChange={(newDate: moment) => {
                  onDateSelected(newDate);
                  setShowDatePicker(false);
                }}
                date={date}
                // Right now we use a fairly small subset of the calendar library. If we leverage more, we'll need to
                // translate more phrases. See: https://github.com/react-dates/react-dates#localization
                phrases={{
                  calendarLabel: window.pgettext(
                    "The label for our calendar picker. This isn't displayed, but may be used by screen readers or other accessibility tools.",
                    "Calendar"
                  ),
                  jumpToPrevMonth: window.pgettext(
                    "The aria-label for the next month button in the calenar picker. This isn't displayed, but may be used by screen readers or other accessibility tools.",
                    "Move backward to switch to the previous month."
                  ),
                  jumpToNextMonth: window.pgettext(
                    "The aria-label for the previous month button in the calenar picker. This isn't displayed, but may be used by screen readers or other accessibility tools.",
                    "Move forward to switch to the next month."
                  ),
                }}
              />
            </div>
          )}
        </RelativePortal>
      </div>
    </View>
  );
};
