/**
 * @flow
 */

import { useEffect, useState } from "react";

// https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
export function useWindowWidth(): number {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return width;
}
