/**
 * @flow
 */

import React, { type Node } from "react";
import { StyleSheet, Text as ReactNativeText } from "react-native";
import type { TextProps } from "../types/styleTypes";

const styles = StyleSheet.create({
  textPrimary: {
    fontFamily: "Nunito, sans-serif",
  },
  textSecondary: {
    fontFamily: "Open Sans, sans-serif",
  },
});

type Props = {|
  ...$Exact<TextProps>,
  // If a fontFamily is explicitly set in the 'style' prop it will override this property.
  fontFamily?: "primary" | "secondary",
|};

export const Text = (props: Props): Node => {
  const { style, fontFamily, ...propsRest } = props;
  const fontStyleToUse = fontFamily === "secondary" ? styles.textSecondary : styles.textPrimary;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactNativeText style={[fontStyleToUse, style]} {...propsRest} />
  );
};
